<script>
import { formatNumberSpaces } from "@/helpers/format-number-spaces";
import { computed, defineComponent, onMounted, ref, toRef, watch } from "vue";
export default defineComponent({
    props: {
        num: Number,
        comp: {
            type: String,
            default: 'div'
        }
    },
    setup(props) {
        const currentNum = ref(0)
        const formattedNum = computed(() => formatNumberSpaces(currentNum.value))
        let interval = null
        let increment = true
        const animate = () => {
            clearInterval(interval)
            const d = Math.abs(currentNum.value - props.num)
            let step = Math.floor(d / 60)
            step = step > 0 ? step : 1
            interval = setInterval(() => {
                currentNum.value += increment ? step : -step
                if ((increment && currentNum.value >= props.num) || (!increment && currentNum.value <= props.num)) {
                    currentNum.value = props.num
                    clearInterval(interval)
                }
            }, 16)
        }
        onMounted(() => {
            animate()
        })
        watch(() => props.num, () => {
            if (props.num > currentNum.value) {
                increment = true
                animate()
            } else {
                increment = false
                animate()
            }
        })
        return { currentNum, formattedNum }
    }

})
</script>

<template>
    <component :is="comp" class="app-number-animate">{{ formattedNum }}</component>
</template>

<style lang="postcss">
</style>